<template>
	<v-container
		class="SignUp"
	>
		
		<v-stepper 
			v-model="step"
		>
			<v-stepper-items
				class="pa-0 ma-0"
			>
				<v-stepper-content
					class="pa-0 ma-0"
					:step="1"
				>
					<Service
						@axios="axios"
						
						:program="program"
						:item="item"
						:callBack="callBackService"
					>
					</Service>
					
				</v-stepper-content>
				
				<v-stepper-content
					class="pa-0 ma-0"
					:step="2"
				>
					<Agency
						@setAgency="setAgency"
						@axios="axios"
						
						:callBack="callBackAgency"
					>
					</Agency>
					
				</v-stepper-content>
				
				<v-stepper-content
					class="pa-0 ma-0"
					:step="3"
				>
					<div 
						class="agent mt-7">
						<v-text-field
							v-model="item.agent.agentName"
							label="대리점명"
							outlined
							color="success2"
							hide-detail
							disabled
							placeholder="대리점이 있는 경우 대리점을 선택하세요"
						></v-text-field>
					</div>
		
					<v-divider class="mb-5"></v-divider>
					
					<v-btn-toggle
						v-model="item.companyType"
						mandatory
						class="d-block"
					>
						<div class="font-weight-bold ml-1 mb-2 text-subtitle-2">사업자 구분</div>
						
						<v-row
							class="pa-0 ma-0"
						>
							<v-col
								v-for="(type, index) in codes.companyTypes"
								:key="index"
								cols="4"
								class="pa-0 ma-0 pr-1"
							>
								<v-btn
									:value="type.code"
									block
									active-class="success2"
									small
								>
									{{ type.codeName }}
								</v-btn>
							</v-col>
						</v-row>
					</v-btn-toggle>
					
					<Shop
						v-if="item.companyType != 'NON'"
						:program="program"
						:item="item"
						:codes="codes"
					>
					</Shop>
					<Shop3
						v-if="item.companyType == 'NON'"
						:program="program"
						:item="item"
						:codes="codes"
					>
					</Shop3>
					
				</v-stepper-content>
				
				<v-stepper-content
					:step="4"
					class="pa-0 ma-0"
				>
					<Etc
						:program="program"
						:item="item"
						:codes="codes"
					>
					</Etc>
						
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		
		<v-btn
			v-if="false"
			@click="nextStep()"
		>
			next
		</v-btn>
		
	</v-container>
</template>
<script>

	
	import Service from '@/views/SignUp/SignUpService'
	import Agency from '@/views/SignUp/SignUpAgency'
	import Shop from '@/views/SignUp/SignUpShop'
	import Shop3 from '@/views/SignUp/SignUpShop3'
	import Etc from '@/views/SignUp/SignUpEtc'

	export default{
		name: 'FindId'
		,created: function(){
			console.log('SignUP  ---------------------------------------------------')
			this.$emit('setProgram', this.program, this.options)
			//this.checkPoint()
			this.getCodes()
		}
		,components: { Service, Agency, Shop, Shop3, Etc}
		,props: ['user', 'callBack']
		,data: function(){
			return{
				program: {
					title: '서비스 신청'
					,not_navigation: true
					,next: {
						title: '다음'
						,do: false
						,callBack: 'nextStep'
					}
				}
				,options: {
				}
				,item: {
					addressDetail: ''
					,addressMain: ''
					,agent: {
						agentSeq: ''
						,agentName: ''
					}
					,agentSeq: ''
					,bankAccountHolder: ''
					,bankAccountNumber: ''
					,bankCode: ''
					,businessCate: ''
					,businessFile: ''
					,businessName: ''
					,businessNumber: ''
					,businessType: ''
					,ceoName: ''
					,certificationInformation: null
					,companyType: 'PUBLIC'
					,mobilPhoneNumber: ''
					,payload: ''
//					,registrationCertificate: null
					,salesName: ''
					,servicesType: ''
					,settlementCycle: ''
					,zipCode: ''
				}
				,password_confirm: ''
				,confirm_code: ''
				,is_confirm: true
				,step: 1
				,steps: 4
				,company_type: '1'
				,codes: {
				}
				,companyTypes: [
					{
						code: 'PUBLIC'
						,codeName: '법인'
					}
					,{
						code: 'PRIVATE'
						,codeName: '개인'
					}
					,{
						code: 'NON'
						,codeName: '비사업자'
					}
				]
				,servicesTypes: [
					{
						code: ''
						,codeName: '선택하세요'
					}
					,
					{
						code: 'A01'
						,codeName: '음식점'
					}
					,{
						code: 'A02'
						,codeName: '방문판매'
					}
					,{
						code: 'A03'
						,codeName: '컨텐츠 판매'
					}
					,{
						code: 'A04'
						,codeName: '도소매 유통'
					}
					,{
						code: 'A05'
						,codeName: '운수업'
					}
					,{
						code: 'A06'
						,codeName: '서비스'
					}
					,{
						code: 'A07'
						,codeName: '숙박업'
					}
					,{
						code: 'A08'
						,codeName: '임대업'
					}
					,{
						code: 'A09'
						,codeName: '농수산업'
					}
					,{
						code: 'A10'
						,codeName: '기타'
					}
				]
				,callBackService: {}
				,callBackAgency: {}
			}
		}
		,methods: {
			go: function(type){
				if(type == 'id'){
					this.$router.push('/Help/FindId')
				}else{
					this.$router.push('/Help/FindPw')
				}
			}
			,find: function(){
				this.$emit('axios',{
					request_type: 'get'
					, request_url: ''
					, request_params: {
						email: this.email
						,phone: this.phone
					}
					,callBack: 'result'
					,dev: 'success'
				})
			}
			,sendSms: function(){
				let call = this.item.mobilPhoneNumber
				if(call == '' || call == undefined){
					alert('');
				}else{
					console.log(call)
				}
			}
			,confirmCode: function(){
				
				let call = this.confirm_code
				if(call == '' || call == undefined){
					alert('');
				}else{
					console.log(call)
				}
			}
			,setProgam: function(step){
				if(step == 'nextStep'){
					this.nextStep()
				}else if(step == 'preStep'){
					this.preStep()
				}
			}
			,preStep () {
				console.log('signup prestep ')
				this.step--
				let n = this.step
				console.log('step : ' + n)
				if(n <= 1){
					this.step = 1
					this.$delete(this.program, 'prev')
					this.$set(this.program, 'next', {title: '다음', do: true, callBack: 'nextStep'})
				}else{
					this.$set(this.program, 'next', {title: '다음', do: true, callBack: 'nextStep'})
				}
			}
			,nextStep () {
				console.log('signup nextstep')
				console.log('step: ' + this.step)
				this.step++
				let n = this.step
				
				if(n >= this.steps) {
					this.step = this.steps
					this.$set(this.program, 'next', {title: '완료', do: true, callBack: 'signUp'})
					return false
				}else if(n == 2){
					this.$set(this.program, 'prev', {title: '', do: false, callBack: 'preStep'})
					this.$set(this.program, 'next', {title: '다음', do: true, callBack: 'nextStep'})
				}else{
					this.$set(this.program, 'prev', {title: '', do: false, callBack: 'preStep'})
					this.$set(this.program, 'next', {title: '다음', do: false, callBack: 'nextStep'})
				}
			}
			,signUp: function(){
//this.item.mobilPhoneNumber = '01024892575'
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/apply'
					,authorize: true
					,multiform: true
					,formData: true
					,request_params: this.item
					,callBack: 'signUpResult'
					,error_message: true
				})
			}
			,signUpResult: function(call){
				
				if(call.result){
					this.$emit('modal', {
						title: '서비스 신청 완료'						
						,contents: '<div class="text-center font-weight-bold text-subtitle-1"><span class="text-color">' + this.user.userId + '</span>님<br/>서비스신청이 완료되었습니다</div> <div class="mt-10 text-center"> 심사가 완료된 후 정상적으로 서비스 이용하실 수 있습니다 <br/> 빠른 시간안에 처리되도록 하겠습니다</div>'
						,contents_type: 'text'
						,accept: '확인'
						,icon: 'mdi-check-circle'
						,icon_color: 'success2'
						,to:'/Home'
					})

					this.$emit('setUser', localStorage.getItem('paylinkt'))
				}else{
					this.$emit('alertMsg', 'error', call.items.content.globalErrors[0].message)
				}
			}
			,axios: function(call){
				this.$emit('axios', call)
			}
			,setAgency: function(call){
				console.log('signup setAgency')
				this.item.agent = call
				this.item.agentSeq = call.agentSeq
			}
			,getCodes: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/apply'
					,authorize: true
					,callBack: 'getCodeResult'
					,error_message: true
				})
			}
			,getCodeResult: function(call){
				console.log('signup getcodereuslt')
				console.log(call)
				this.codes = call.items.content
				//this.$set(this.codes, 'servicesTypes', this.servicesTypes)
				this.$set(this.codes, 'companyTypes', this.companyTypes)
			}
			,checkPoint: function(){
				if(!this.user.mobilPhoneNumber || this.user.mobilPhoneNumber == '' || this.user.mobilPhoneNumber == undefined || !this.user.payload || this.user.payload == '' || this.user.payload == undefined){
					this.$emit('alertMsg', 'error', '본인인증이 정보가 없거나 만료되었습니다. 본인인증 후 다시 시도해주세요')
					this.$router.push('/SignUp')
				}
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
				
					console.log('watch signup callback')
					console.log(call.name)
					
					if(call.name == 'signUpResult'){
						this.signUpResult(call)
					}else if(call.name == 'nextStep'){
						this.nextStep()
					}else if(call.name == 'preStep'){
						this.preStep()
					}else if(call.name == 'signUp'){
						this.signUp()
					}else if(call.name == 'getCodeResult'){
						this.getCodeResult(call)
					}else if(call.name == 'getAgencyResult'){
						this.callBackAgency = call
					}else if(call.name == 'niceResult'){
						this.callBackService = call
					}
				}
			}
			,company_type: {
				immediate: true
				,handler: function(call){
					this.$set(this.item, 'company_type', call)
				}
			}
			,item: {
				deep: true
				,handler: function(call){
					
					if(this.step == 1){
						if(this.item.payload == undefined || this.item.payload == ''){
							return false
						}
						if(this.item.mobilPhoneNumber == undefined || this.item.mobilPhoneNumber == ''){
							return false
						}
						
						this.program.next.callBack = 'nextStep'
						this.program.next.do = true
						console.log(this.program)
						
					}else if(this.step == 2){
						
						this.program.next.callBack = 'nextStep'
						this.program.next.do = true
						
					}else if(this.step == 3){
						
						if(this.item.companyType != 'NON'){
							
							if(call.businessName == undefined || call.businessName == ''){
								this.program.next.do = false
								return false
							}
							
							if(call.businessNumber  == undefined || call.businessNumber  == ''){
								this.program.next.do = false
								return false
							}
							
							if(call.servicesType == undefined || call.servicesType == ''){
								this.program.next.do = false
								return false
							}
						}else if(this.item.companyType == 'NON'){
							
							if(call.ceoName == undefined || call.ceoName == ''){
								this.program.next.do = false
								return false
							}
							
							if(call.businessNumber1  == undefined || call.businessNumber1  == ''){
								this.program.next.do = false
								return false
							}
							
							if(call.businessNumber2 == undefined || call.businessNumber2 == ''){
								this.program.next.do = false
								return false
							}
							
							call.businessNumber = call.businessNumber1 + call.businessNumber2
							
							if(call.salesName == undefined || call.salesName == ''){
								this.program.next.do = false
								return false
							}
							
							if(call.servicesType == undefined || call.servicesType == ''){
								this.program.next.do = false
								return false
							}
							
							
						}
					
						this.program.next.callBack = 'nextStep'
						this.program.next.do = true
						
					}else if(this.step == 4){
						this.program.next.callBack = 'signUp'
						this.program.next.do = true
					}
				}
			}
			,agency: {
				deep: true
				,handler: function(){
				}
			}
		}
	}
</script>
<style>
	td { padding: 5px 2px !important; height: auto; border-bottom: 2px solid #ddd; font-size: 0.8em !important; font-weight: bold;}
	
	
	.v-text-field input{
		font-size: 0.8em;
	}
	.v-label {
	font-size: 0.8em;
	}
	
	
	.v-stepper, .v-stepper__header {
		box-shadow: none
	}
	
	
	.label {color: black;}
	.file .v-label {left: -26px !important;}
	
	.agent .theme--light.v-input--is-disabled input {color: black !important;}
	.agent .v-text-field--outlined>.v-input__control>.v-input__slot {background: #eee;}
	
	.shop .v-label {font-size: 1.2em !important; font-weight: bold; color: black !important; top: 0 !important;}
	.shop .v-input--is-disabled .v-label {font-size: 1.2em !important; font-weight: bold; color: black !important; top: 18px !important;}
	
	.select .v-select__slot .v-label--active {font-size: 1.2em !important; font-weight: bold; color: black !important;}
	.select .v-input--is-label-active legend {width: 81px !important;}
	.v-select__selection {min-height: 0;}
	
	.select2 .v-select__slot .v-label--active {font-size: 1.2em !important; font-weight: bold; color: black !important;}
	.select2 .v-input--is-label-active legend {width: 65px !important;}
	
	.select3 .v-select__slot .v-label--active {font-size: 1.2em !important; font-weight: bold; color: black !important;}
	.select3 .v-input--is-label-active legend {width: 35px !important;}
	
</style>







