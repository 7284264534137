<template>

	<div class=" mt-10">
	
		<div class="shop">
			
			<v-text-field
				v-model="item.businessName"
				label="상호명(법인명)"
				placeholder="상호명을 입력하세요"
				hide-detail
				class="mt-7"
				color="success2"
			></v-text-field>
			
			<v-text-field
				v-model="item.ceoName"
				label="대표자 성명"
				placeholder="대표자 성명을 입력하세요"
				color="success2"
				hide-detail
			></v-text-field>
			
			<v-text-field
				v-model="item.businessNumber"
				label="사업자등록번호"
				placeholder="사업자등록번호를 입력하세요"
				color="success2"
				hide-detail
			></v-text-field>
			
			<div class="file">
				<v-file-input
					v-model="item.registrationCertificate"
					label="사업자등록증"
					placeholder="사업자 등록증을 첨부하세요"
					color="success2"
					hide-detail
					
					accept="image/png, image/jpeg, image/bmp"
					prepend-inner-icon="mdi-image"
					prepend-icon=""
				></v-file-input>
			</div>
				
			<v-text-field
				v-model="item.salesName"
				label="판매자명"
				placeholder="판매자명을 입력하세요"
				color="success2"
				hide-detail
			></v-text-field>
		</div>
		
		<div class="select3">
			<v-row
			>
				<v-col
					cols="6"
				>
					<v-select 
						v-model="item.businessType"
						:items="codes.businessTypes"
						item-text="codeName"
						item-value="code"
						label="업종"
						outlined
						color="success2"
						hide-detail
						class="pa-0 ma-0"
						value="''"
					></v-select>
				</v-col>
				<v-col
					cols="6"
				>
					<v-select 
						v-model="item.businessCate"
						:items="codes.businessCate"
						item-text="codeName"
						item-value="code"
						label="업태"
						outlined
						color="success2"
						hide-detail
						class="pa-0 ma-0"
						value="''"
					></v-select>
				</v-col>
			</v-row>
		</div>
		
		<div class="select">
			<v-select 
				v-model="item.servicesType"
				:items="codes.servicesTypes"
				item-text="codeName"
				item-value="code"
				label="서비스 구분"
				outlined
				color="success2"
				hide-detail
				class="pa-0 ma-0"
				value="''"
			></v-select>
		</div>
	</div>
</template>

<script>
	
	export default{
		name: 'Shop'
		,props: ['program', 'item', 'codes']
		,components: {}
		,data: function(){
			return {
				company_type: '1'
			}
		}
		,methods: {
		}
		,watch: {
			company_type: {
				immediate: true
				,handler: function(){
					this.$set(this.item, 'company_type', this.company_type)
				}
			}
		}
	}
</script>

<style>
</style>


