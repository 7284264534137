<template>
	<div class="mt-10">
		
		<div class="shop">
			<v-text-field
				v-model="item.zipCode"
				label="우편번호"
				placeholder="우편번호를 입력하세요"
				
				hide-detail
				readonly
				color="success2"
				append-icon="search"
				@click:append="daumPost"
			></v-text-field>
			
			<v-text-field
				v-model="item.addressMain"
				label="주소"
				placeholder="주소를 입력하세요"
				
				hide-detail
				readonly
				color="success2"
				
			></v-text-field>
			
			<v-text-field
				v-model="item.addressDetail"
				label="상세주소"
				placeholder="상세주소를 입력하세요"
				
				hide-detail
				color="success2"
			></v-text-field>
		</div>
		
		<div class="shop"> 
		
			<v-btn-toggle
				v-model="item.settlementCycle"
				mandatory
				class="d-block"
			>	
				<div class="font-weight-bold ml-1 mb-2 text-subtitle-2">수수료 선택</div>
				
				<v-row
					class="pa-0 ma-0"
				>
					<v-col
						v-for="(item, index) in codes.settlementCycle"
						:key="index"
						class="pa-0 ma-0"
						:class="index == 0 ? 'pr-1' : 'pl-1'"
					>
						<v-btn
							:value="item.code"
							block
							:active-class="index == 0 ? 'success2' : ''"
							small
						>
							D+{{ item.code }}일 정산/{{ item.name }}%
						</v-btn>
					</v-col>
				</v-row>
			</v-btn-toggle>
		</div>
			
		<div class="select2 mt-5">
			<v-select 
				v-model="item.bankCode"
				:items="codes.bankCodes"
				item-text="codeName"
				item-value="code"
				label="정산 은행"
				outlined
				color="success2"
				hide-detail
				class="pa-0 ma-0"
				value="''"
			></v-select>
		</div>
		
		<div class="shop">
			
			<v-text-field
				v-model="item.bankAccountNumber"
				label="계좌번호"
				placeholder="계좌번호를 입력하세요"
				
				hide-detail
				color="success2"
			></v-text-field>
			
			<v-text-field
				v-model="item.bankAccountHolder"
				label="예금주"
				placeholder="예금주를 입력하세요"
				
				hide-detail
				color="success2"
			></v-text-field>
		</div>
		
		<DaumPost
			:modal="daumPostUp"
			
			@removeModal="removeModal"
			@callBack="addPost"
		></DaumPost>
	</div>
	
</template>

<script>

	import DaumPost from '@/components/DaumPost'
	
	export default{
		name: 'Etc'
		,props: ['item', 'program', 'codes']
		,components: {DaumPost}
		,data: function(){
			return {
			
				daumPostUp: false
				,fee_type: '1'
				,fees: [
					{
						id: 1
						,name: 'D+1일 정산'
						,fee: 4.2
					}
					,{
						id:2
						,name: 'D+5일 정산'
						,fee: 3.5
					}
				]
				,bankes: [
					{
						code: ''
						,name: '은행을 선택하세요'
					}
					,{
						code: 'A01'
						,name: '국민은행'
					}
					,{
						code: 'A02'
						,name: '우리은행'
					}
					,{
						code: 'A03'
						,name: '기업은행'
					}
					,{
						code: 'A04'
						,name: '농협'
					}
					,{
						code: 'A05'
						,name: '기타'
					}
				]
			}
		}
		,methods: {
			
			daumPost: function(){
				this.daumPostUp = true
			}
			,addPost: function(call){
				this.$set(this.item, 'zipCode', call.zonecode)
				this.$set(this.item, 'addressMain', call.address)
			}
			,removeModal: function(){
				console.log('removeModal')
				this.daumPostUp = false
			}
		}
		,watch: {
			fee_type: {
				immediate: true
				,handler: function(){
					this.$set(this.item, 'fee_type', this.fee_type)
				}
			}
		}
	}
</script>

<style>
</style>



















