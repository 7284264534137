<template>

	<div class=" mt-10">
	
		<div class="shop">
			
			<v-text-field
				v-model="item.ceoName"
				label="성함"
				placeholder="대표자 성명을 입력하세요"
				hide-detail
				class="mt-7"
				color="success2"
			></v-text-field>
			
			<v-row>
				<v-col>
					<v-text-field
						v-model="item.businessNumber1"
						label="주민등록번호"
						placeholder="생년월일"
						color="success2"
						hide-detail
						type="number"
						:rules="[rules.businessNumber1]"
					></v-text-field>
				</v-col>
				
				<v-col>
					<v-text-field
						v-model="item.businessNumber2"
						placeholder=""
						color="success2"
						hide-detail
						type="password"
						:rules="[rules.businessNumber2]"
						ref="businessNumber2"
					></v-text-field>
				</v-col>
			</v-row>
				
			<v-text-field
				v-model="item.businessNumber"
				class="d-none"
			></v-text-field>
				
			<v-text-field
				v-model="item.salesName"
				label="판매자명"
				placeholder="판매자명을 입력하세요"
				color="success2"
				hide-detail
			></v-text-field>
		</div>
		
		<div class="select">
			<v-select 
				v-model="item.servicesType"
				:items="codes.servicesTypes"
				item-text="codeName"
				item-value="code"
				label="서비스 구분"
				outlined
				color="success2"
				hide-detail
				class="pa-0 ma-0"
				value="''"
			></v-select>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Shop3'
		,props: ['program', 'item', 'codes']
		,data: function(){
			return {
				company_type: 3
				,rules: {
					businessNumber1: value => {

						const max = 6
						if(value){

							if(value.length > max){
								this.item.businessNumber1 = value.substring(0, max)
							}
							if(value.length == max){
								this.$refs.businessNumber2.focus()
								return true
							}
						}
						return false
					}
					,businessNumber2: value => {
						const max = 7
						if(value){
							if(value.length > max){
								this.item.businessNumber2 = value.substring(0, max)
							}
							if(value.length == max){
								return true
							}
						}
						return false
					}
				}
			}
		}
		
	}
</script>
